const menuConfig = [
  {
    title: 'Home',
    path: '/'
  },
  {
    title: 'About us',
    path: ''
  },
  {
    title: 'Available Courts',
    path: '/services'
  },
  {
    title: 'Coming soon',
    path: ''
  },
  {
    title: 'Contact Us',
    path: '/contact-us'
  }
];

export default menuConfig;
